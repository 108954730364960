<template>
  <div
    class="TableParent"
    :style="{
      maxHeight: json.options.maxHeight || '',
      minHeight: json.options.minHeight || '',
      borderRadius: json.options.borderRadius || '',
    }"
  >
    <clasic-modal
      v-model="modalSLot.show"
      :extendedMode="modalSLot.extendedMode"
    >
      <slot
        name="modal-slot"
        v-bind:row="modalSLot.data || {}"
        v-bind:closeModal="() => (modalSLot.show = false)"
        v-bind:actionBtn="modalSLot.actionBtn"
      ></slot>
    </clasic-modal>
    <div
      v-if="searchOptions.length"
      class="search"
      :style="cellsCanClick ? '' : 'pointer-events:none !important;'"
    >
      <div v-for="(e, i) in searchOptions" :key="i" class="btn">
        <div class="btn_icon_search">
          <img src="@/assets/media/for_table/Search.svg" alt="" />
        </div>
        <div class="btn_content">
          <span class="btn_content_title">{{ e.title }}</span>
          <input type="text" class="btn_content_input" />
        </div>
        <div class="btn_icon_vector">
          <img src="@/assets/media/for_table/vector_bottom.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="table_inner" ref="Table_Parent">
      <table :key="tableKey" :id="tableId" ref="Table" class="Table">
        <thead
          ref="thead"
          :style="
            (cellsCanClick || !doNotDisableFiltreControl
              ? ''
              : 'pointer-events:none !important;') +
              (json.options.spread ? 'white-space: nowrap;' : '')
          "
        >
          <tr
            ref="theadRow"
            class="Table_row Table_head"
            :class="{ minimizeHead: json.options.minimizeHead }"
          >
            <th
              v-if="fixedCols.header.fixedLeft.length"
              class="fixedTableCol Table_head_cell"
              style="left: 0;width: 2rem !important;"
            >
              <table
                :style="headerRowHeight ? `height:${headerRowHeight}px;` : ''"
              >
                <tr class="Table_row">
                  <header-cell
                    v-for="(head, i) in fixedCols.header.fixedLeft"
                    :key="i"
                    :index="i"
                    :cell="head"
                    @sortTable="sortTable"
                    @select-all="selectAll"
                  />
                </tr>
              </table>
            </th>
            <header-cell
              v-for="(head, i) in fixedCols.header.notFixed"
              :key="i"
              :index="i + fixedCols.header.len1"
              :cell="head"
              @sortTable="sortTable"
            />
            <th
              v-if="fixedCols.header.fixedRight.length"
              class="fixedTableCol Table_head_cell"
              style="right: 0;"
            >
              <table
                :style="headerRowHeight ? `height:${headerRowHeight}px;` : ''"
              >
                <tr class="Table_row">
                  <header-cell
                    v-for="(head, i) in fixedCols.header.fixedRight"
                    :key="i"
                    :index="i + fixedCols.header.len2"
                    :cell="head"
                    @sortTable="sortTable"
                  />
                </tr>
              </table>
            </th>
          </tr>
          <tr
            v-if="
              !json.options.hideBottomSearch &&
                allowedHeaders.find((e) => e.bottomHead)
            "
            class="bottomPanel"
          >
            <td
              v-if="fixedCols.headerSearch.fixedLeft.length"
              class="fixedTableCol topPanelCell"
              style="left: 0;"
            >
              <table>
                <tr class="bottomPanel">
                  <header-search-cell
                    v-for="(e, i) in fixedCols.headerSearch.fixedLeft"
                    :key="i"
                    :index="i"
                    :cell="e"
                    :resetSearch="resetSearch"
                    :selectedItemsIndex="selectedItemsIndex"
                    :avizoMode="
                      Data.institution ? Data.institution.avizoMode : true
                    "
                    :approveMode="
                      Data.institution ? Data.institution.approveMode : true
                    "
                    @searchManager="searchManager"
                    @loadSearchData="loadSearchData"
                    @setSelectedItemsWindow="setSelectedItemsWindow"
                    @clearAllFilter="clearAllFilter"
                  />
                </tr>
              </table>
            </td>
            <header-search-cell
              v-for="(e, i) in fixedCols.headerSearch.notFixed"
              :key="i"
              :index="i + fixedCols.headerSearch.len1"
              :cell="e"
              :resetSearch="resetSearch"
              :selectedItemsIndex="selectedItemsIndex"
              :avizoMode="Data.institution ? Data.institution.avizoMode : true"
              :approveMode="
                Data.institution ? Data.institution.approveMode : true
              "
              @searchManager="searchManager"
              @loadSearchData="loadSearchData"
              @setSelectedItemsWindow="setSelectedItemsWindow"
              @clearAllFilter="clearAllFilter"
            />
            <td
              v-if="fixedCols.headerSearch.fixedRight.length"
              class="fixedTableCol topPanelCell"
              style="right: 0;"
            >
              <table>
                <tr class="bottomPanel">
                  <header-search-cell
                    v-for="(e, i) in fixedCols.headerSearch.fixedRight"
                    :key="i"
                    :index="i + fixedCols.headerSearch.len2"
                    :cell="e"
                    :resetSearch="resetSearch"
                    :selectedItemsIndex="selectedItemsIndex"
                    :avizoMode="
                      Data.institution ? Data.institution.avizoMode : true
                    "
                    :approveMode="
                      Data.institution ? Data.institution.approveMode : true
                    "
                    @searchManager="searchManager"
                    @loadSearchData="loadSearchData"
                    @setSelectedItemsWindow="setSelectedItemsWindow"
                    @clearAllFilter="clearAllFilter"
                  />
                </tr>
              </table>
            </td>
          </tr>
        </thead>
        <tbody
          v-if="tableBody.length && tableBody[0] !== false"
          :style="cellsCanClick ? '' : 'pointer-events:none !important;'"
        >
          <template v-for="(row, i) in fixedCols.body">
            <tr
              :class="{
                rowMention:
                  showSubTableSlotIndex === i ||
                  (row.mention && !(row.subData && !row.length)),
                subRowMention: showSubTableSlotIndex === i,
              }"
              :style="
                showSubTableSlotIndex === i && headerHeight > 0
                  ? `position: sticky;top:${headerHeight}px;z-index:1;`
                  : ''
              "
              :key="i"
              class="Table_row tbody"
            >
              <td
                v-if="row.subData && !row.length"
                :colspan="allowedHeaders.length"
                class="tablePreloaderInner subRow"
                style="position: relative; padding-left: 0; padding-right: 0"
              >
                <div
                  class="fa-3x"
                  style="
                    position: sticky;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                  "
                  :style="`width:${tableClientWidth}px;`"
                >
                  <i
                    v-if="!row.isEmpty"
                    class="fas fa-sync fa-spin"
                    style=""
                  ></i>
                  <div
                    v-else
                    class="TableNotFound"
                    style="color: #553636; margin: 0 !important"
                  >
                    Nu s-au gasit rezultate
                  </div>
                </div>
              </td>
              <template v-else>
                <td
                  v-if="row.fixedLeft.length"
                  class="fixedTableCol topPanelCell"
                  style="left: 0;"
                >
                  <table>
                    <tr class="bottomPanel">
                      <body-cell
                        v-for="(cell, i2) in row.fixedLeft"
                        :key="i2"
                        :panelButtonsIndex="panelButtonsIndex"
                        :class="{
                          subRowB:
                            row.bottomBorder || showSubTableSlotIndex === i,
                        }"
                        :index="i2"
                        :cell="cell"
                        :rowIndex="i"
                        :appendToColIndex="0"
                        :row="row.fixedLeft"
                        :img="img"
                        :iconsMap="iconsMap"
                        :allowedHeaders="allowedHeaders"
                        :tableClientWidth="tableClientWidth"
                        :showSubTableSlotIndex="showSubTableSlotIndex"
                        :checkedRows="checkedRows"
                        @PBI="(x) => (panelButtonsIndex = x)"
                        @subTable="subTable"
                        @checkboxManager="checkboxManager"
                      />
                    </tr>
                  </table>
                </td>
                <body-cell
                  v-for="(cell, i2) in row.notFixed"
                  :key="i2"
                  :panelButtonsIndex="panelButtonsIndex"
                  :class="{
                    subRowB: row.bottomBorder || showSubTableSlotIndex === i,
                  }"
                  :index="i2"
                  :cell="cell"
                  :rowIndex="i"
                  :appendToColIndex="fixedColsIndexes[0]"
                  :row="row.notFixed"
                  :img="img"
                  :iconsMap="iconsMap"
                  :allowedHeaders="allowedHeaders"
                  :tableClientWidth="tableClientWidth"
                  :showSubTableSlotIndex="showSubTableSlotIndex"
                  :checkedRows="checkedRows"
                  @PBI="(x) => (panelButtonsIndex = x)"
                  @subTable="subTable"
                  @checkboxManager="checkboxManager"
                />
                <td
                  v-if="row.fixedRight.length"
                  class="fixedTableCol topPanelCell"
                  style="right: 0;"
                >
                  <table>
                    <tr class="bottomPanel">
                      <body-cell
                        v-for="(cell, i2) in row.fixedRight"
                        :key="i2"
                        :panelButtonsIndex="panelButtonsIndex"
                        :index="i2"
                        :cell="cell"
                        :rowIndex="i"
                        :appendToColIndex="fixedColsIndexes[2]"
                        :row="row.fixedRight"
                        :img="img"
                        :iconsMap="iconsMap"
                        :allowedHeaders="allowedHeaders"
                        :tableClientWidth="tableClientWidth"
                        :showSubTableSlotIndex="showSubTableSlotIndex"
                        :checkedRows="checkedRows"
                        @PBI="(x) => (panelButtonsIndex = x)"
                        @subTable="subTable"
                        @checkboxManager="checkboxManager"
                      />
                    </tr>
                  </table>
                </td>
              </template>
            </tr>
            <tr
              v-if="showSubTableSlotIndex === i"
              class="subRowMentionHover rowMention rowSlot"
              :key="i + 0.5"
            >
              <td :colspan="allowedHeaders.length" class="subRowSlot">
                <slot name="subitem" v-bind:row="row._Data || {}">
                  Lipsește
                </slot>
              </td>
            </tr>
          </template>
          <tr v-if="tableBody.length < 8">
            <td style="padding: 0 !important" :colspan="allowedHeaders.length">
              <div class="tableShadowBottom"></div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              :colspan="allowedHeaders.length"
              class="tablePreloaderInner"
              style="position: relative; padding-left: 0; padding-right: 0"
            >
              <div
                class="fa-3x"
                style="
                  position: sticky;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  margin-top: 2rem;
                  margin-bottom: 2rem;
                "
                :style="`width:${tableClientWidth}px;`"
              >
                <i
                  v-if="tableBody[0] !== false"
                  class="fas fa-sync fa-spin"
                  style=""
                ></i>
                <div v-else class="TableNotFound">Nu s-au gasit rezultate</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="!(json.options && json.options.hideControl)"
      class="panelControl"
      :class="{ tableShadowTop: tableBody.length < 7 }"
      :style="cellsCanClick ? '' : 'pointer-events:none !important;'"
    >
      <div class="panelControl_status">
        Total afișat: {{ currentShowed }}
        <i v-show="loadingData" class="fas fa-sync fa-spin"></i>
      </div>
      <div class="panelControl_options">
        <div class="totally_displayed">
          <select
            @change="(x) => changeRows(x, true)"
            v-model="optionRowsKey"
            class="displayed_btn"
            style="background-image: url(@/assets/media/for_table/vector_bottom.svg);"
          >
            <option v-for="i in optionRows" :key="i" :value="i">
              <span>Rânduri pe pagină: {{ i }}</span>
            </option>
            <option v-if="defaultRowsDisplayed" :value="defaultRowsDisplayed">
              <span>Rânduri pe pagină: {{ defaultRowsDisplayed }}</span>
            </option>
          </select>
        </div>
        <div class="currentDisplayed">
          <span>
            {{ currentDisplayed }}
          </span>
        </div>
        <div class="panelControl_btns">
          <div @click="backShow()" class="back">
            <img src="@/assets/media/for_table/vector_left.svg" alt="Back" />
          </div>
          <div @click="nextShow()" class="next">
            <img src="@/assets/media/for_table/vector_right.svg" alt="Next" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./css/table.css"></style>
<style src="./css/tableSearchSupport.css"></style>

<script>
import SearchSupport from "./tableSearchSupport.js";
import HeaderCell from "./HeaderCell";
import HeaderSearchCell from "./HeaderSearchCell";
import BodyCell from "./BodyCell";

import I1 from "@/assets/media/for_table/dolar.svg";
import I2 from "@/assets/media/for_table/menu.svg";
import I3 from "@/assets/media/for_table/pencil.svg";
import I4 from "@/assets/media/for_table/trash.svg";
import I5 from "@/assets/media/for_table/generate_RN.svg";
import I6 from "@/assets/media/for_table/download_DOC.svg";
import I7 from "@/assets/media/needs.svg";
import I8 from "@/assets/media/for_table/Search_more.svg";
import I9 from "@/assets/media/for_table/confirm.svg";
import I10 from "@/assets/media/for_table/cancel.svg";
import I11 from "@/assets/media/for_table/view_more.svg";
import I12 from "@/assets/media/for_table/report.svg";
import I13 from "@/assets/media/for_table/revizuire.svg";
import I14 from "@/assets/media/for_table/timeline.svg";
import I15 from "@/assets/media/pdf.svg";
import I16 from "@/assets/media/departments.svg";

const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
  strategy: "scroll",
});
let rowsHasBeenModified = false;

export default {
  props: {
    Data: {
      type: Object,
      required: true,
      default: () => {},
    },
    viewRows: {
      type: Array,
      required: false,
      default: () => [],
    },
    avizoMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    useSearchModule: {
      type: String,
      required: false,
      default: "",
    },
    fixedFilter: {
      type: String,
      required: false,
      default: "",
    },
    disabledCols: {
      type: Array,
      default: () => [],
    },
    disableSubData: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [SearchSupport],
  components: {
    HeaderCell,
    HeaderSearchCell,
    BodyCell,
  },
  data() {
    return {
      img: [
        I1,
        I2,
        I3,
        I4,
        I5,
        I6,
        I7,
        I8,
        I9,
        I10,
        I11,
        I12,
        I13,
        I14,
        I15,
        I16,
        ["fas fa-id-card"],
      ],
      modalSLot: {
        show: false,
        actionBtn: 0,
        data: null,
        extendedMode: "",
      },
      panelButtonsIndex: -1,
      showOnlyCurrentItemsTotalShow: false,
      totalItemsCount: false,
      doNotDisableFiltreControl: true,
      tableKey: 0,
      tableId: "IDTB",
      tableRect: {},
      json: this.Data,
      subTableData: {},
      totallyShowed: {
        show: 0,
        currentIndex: 1,
        saw: [],
        totalItems: 0,
      },
      tableHead: [],
      tableBody: [],
      searchOptions: [],
      tableWidth: 0,
      tableClientWidth: 0,
      headerRowHeight: 0,
      headerHeight: 0,
      resetSearch: 0,
      optionRows: [15, 30, 50],
      optionRowsKey: ((x) =>
        x
          ? parseInt(x)
          : this.Data.options && parseInt(this.Data.options.show)
          ? parseInt(this.Data.options.show)
          : 15)(localStorage.getItem("tableShowPerPage")),
      subHead: [],
      subBody: [],
      displayedSubTable: false,
      displayedSubData: false,
      defaultRowsDisplayed: false,
      quikFiltreState: [],
      allSelected: false,
      iconsMap: {
        "fas fa-clipboard-check": {
          title: "Aprobat",
          css: "font-size:2.2rem;color:#569a36;",
        },
        "far fa-clock": {
          title: "Așteptare aprobare",
          css: "font-size:2.2rem;color:#f2da07;",
        },
        "fas fa-times-circle": {
          title: "Respins",
          css: "font-size:2.2rem;color:#ec4a4a;",
        },
        "fas fa-ban": {
          title: "Anulat",
          css: "font-size:2.2rem;color:#ec4a4a;",
        },
        "fas fa-low-vision": {
          title: "În proces <br/>de avizare",
          css: "font-size:2.2rem;color:#39f;",
        },
        "fas fa-unlock": {
          title: "Deblocat",
          css: "font-size:2.2rem;color:#3c763d;",
        },
        "fas fa-exclamation-circle": {
          title:
            "Așteptare editare în urma anulării <br> de către un administrator",
          css: "font-size:2.2rem;color:#3f51b5;",
        },
        "fas fa-lock": {
          title: "Blocat",
          css: "font-size:2.2rem;color:#a94442;",
        },
        "complex.svg": {
          title: "Complex",
          css: "height: 2.4rem;",
        },
        "fas fa-check-double": {
          title: "Similar",
          css: "font-size:2.2rem;color:#286090;",
        },
        "far fa-calendar-check": {
          title: "Așteptare aprobare <br/> în urma avizării",
          css: "font-size:2.2rem;color:#337ab7;",
        },
      },
      checkedRows: this.viewRows ? this.viewRows : [],
      selectedItemsIndex: -1,
      showSubTableSlotIndex: -1,
    };
  },
  computed: {
    currentShowed() {
      const prepare = !this.tableBody[0]
        ? "0/0"
        : `${this.totallyShowed.saw.length}/${Math.max(
            this.totallyShowed.saw.length,
            this.totalItemsCount ||
              (this.showOnlyCurrentItemsTotalShow
                ? this.json.body.filter((e) => e).length
                : this.totallyShowed.totalItems)
          )}`;

      return prepare;
    },
    cellsCanClick() {
      const data = this.json.body;
      return !(!data.length || (data.length === 1 && data[0] === false));
    },
    allowedHeaders() {
      const x = this.tableHead.filter((e) => e.show !== false);
      if (!this.disabledCols.length) return x;
      return x.filter((...p) => !this.disabledCols.includes(p[1]));
    },
    currentDisplayed() {
      return !this.tableBody[0]
        ? "0-0"
        : this.totallyShowed.currentIndex +
            (this.totallyShowed.currentIndex + this.totallyShowed.show - 1 <=
            this.json.body.length
              ? "-" +
                (this.totallyShowed.currentIndex + this.totallyShowed.show - 1)
              : "-" + this.json.body.length);
    },
    searchData() {
      const quikFiltreMap = {
        true: { data: [], label: ["name"], loading: false },
        number: {},
        numberFloat: {},
        type: { value: [false, false, false, false] },
        status: {
          value: [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ],
        },
        statusReference: {
          value: [false, false, false, false, false, false, false, false],
        },
        acquisitionProductStatus: { value: [false, false] },
        acquisitionStatus: { value: [false, false, false, false, false, false] },
        paap: { value: [false, false, false, false] },
        userStatus: { value: [false, false, false] },
        needReferate: { value: [false, false] },
        date: { value: null, type: "date", valueType: "YYYY-MM-DD hh:mm:ss" },
        list: { value: null },
      };

      return this.allowedHeaders.map(
        (e) =>
          (e = e.bottomHead
            ? Object.assign(
                e.bottomHead.valuesMap
                  ? { ...e.bottomHead.valuesMap }
                  : { ...quikFiltreMap[String(e.bottomHead.quikFiltre)] },
                e
              )
            : { bottomHead: {} })
      );
    },
    isEmpty() {
      return this.json.body.length === 1 && this.json.body[0] === false;
    },
    fixedColsIndexes() {
      const length = this.allowedHeaders.length;

      let leftEnd = 0;
      let rightStart = 0;
      let rightEnd = 0;
      for (let i = 0; i < length; i++) {
        const val = this.allowedHeaders[i];
        if (!val?.fixed || rightEnd) {
          continue;
        }

        if (val.fixed.left) {
          leftEnd = i + 1;
        } else if (val.fixed.right) {
          rightStart = i;
          rightEnd = length - i;
        }
      }

      return [leftEnd, rightEnd, rightStart];
    },
    fixedCols() {
      const x = this.fixedColsIndexes;
      const prepareObj = (arr) => {
        if (!Array.isArray(arr)) return [];


        const {
          _ID,
          subData,
          parentId,
          mention,
          isEmpty,
          bottomBorder,
          _Data,
          id,
        } = arr;
        const getArr = (arr) =>
          Object.assign(arr, {
            _ID,
            subData,
            parentId,
            isEmpty,
            bottomBorder,
            mention,
            id,
          });
        const prepare = {
          fixedLeft: x[0] ? getArr(arr.slice(0, x[0])) : [],
          notFixed: x[0]
            ? getArr(arr.slice(x[0], x[1] > 0 ? -x[1] : Infinity))
            : arr,
          fixedRight: x[2] > 0 ? getArr(arr.slice(x[2])) : [],
          mention,
          subData,
          isEmpty,
          length: arr.length,
        };
        prepare.len1 = prepare.fixedLeft.length;
        prepare.len2 = prepare.len1 + prepare.notFixed.length;
        prepare._Data = _Data;

        return prepare;
      };

      return {
        header: prepareObj(this.allowedHeaders),
        headerSearch: prepareObj(this.searchData),
        body: this.tableBody.map((e, i) => {
          if (!Array.isArray(e)) {
            return {
              fixedLeft: [],
              notFixed: [true],
              fixedRight: [],
            };
          }
          if (!Number.isInteger(e._ID)) e._ID = i;

          return prepareObj(e);
        }),
      };
    },
  },
  watch: {
    Data: {
      handler(val) {
        if (val.options && val.options.totalItems) {
          this.totallyShowed.totalItems = val.options.totalItems;
        } else {
          this.totallyShowed.totalItems = val.body.length;
        }
        if (this.json.options?.hotRealodBody)
          this.tableBody = this.json.body = val?.body;

        this.updateJson();
        this.jsonTableInit();

        if (this.allSelected) {
          this.checkedRows = this.json.body
            .filter((el) => el[0].checkbox)
            .map((el) => ({ index: el._ID, id: el.id }));
        }
      },
      deep: true,
    },
    optionRowsKey(val) {
      this.$emit("changePage", val);

      if (val !== 15) {
        localStorage.setItem("tableShowPerPage", val);
      } else {
        localStorage.removeItem("tableShowPerPage");
      }
    },
  },
  methods: {
    setSelectedItemsWindow(index) {
      if (index === undefined) {
        return;
      }
      this.selectedItemsIndex = index;
    },
    selectAll(a) {
      if (a.target.checked) {
        this.checkedRows = this.json.body
          .filter((el) => el[0].checkbox)
          .map((el) => {
            return { index: el._ID, id: el.id };
          });
      } else {
        this.checkedRows = [];
      }
      this.$emit("checkboxManager", [...this.checkedRows]);
      this.$emit("selectAll", a.target.checked);

      this.allSelected = a.target.checked;
    },
    async loadSearchData(index, scope) {
      const response = await this.getDropdownData(scope.inputData);
      scope.callback(response);
    },
    searchManager(index, data) {
      for (let i = 0; i < index; i++) {
        const find = this.searchData[i];
        if (find?.show === false) {
          index++;
        }
      }

      const findHead = this.searchData[index];
      if (!findHead) {
        this.$toastr.e(
          "A apărut o eroare. Controlați consola și anunțați administratorul."
        );
        console.error(
          "[FE] Au apărut probleme cu indexarea coloanelor în tabelă."
        );
        return;
      }

      findHead.inputValue = data?.inputData?.inputValue || null;

      const prepare = [...this.searchData];
      prepare.splice(index, 1, findHead);
      this.prepareGeneralQuery(prepare);
    },
    ButtonClick(e) {
      if (e) {
        this.$emit("ButtonClick", e);
      }
    },
    checkboxManager() {
      this.$emit("checkboxManager", [...this.checkedRows]);
    },
    sortTable(col) {
      this.showSubTableSlotIndex = -1;
      const x = this.allowedHeaders[col];
      const setSorted = (val, obj) => this.$set(obj || x, "$sorted", val);

      if (!x.sort) return;
      if (this.displayedSubTable) {
        this.tableBody.splice(this.displayedSubTable, 1);
        this.displayedSubTable = false;
      }
      if (!x.$sorted) {
        this.allowedHeaders.forEach((el) => setSorted(false, el));
        setSorted(1);
      } else if (x.$sorted === 1) setSorted(2);
      else {
        if (this.quikFiltreState.length) {
          setSorted(false);
          this.tableBody = [...this.quikFiltreState];
          return;
        }
        setSorted(false);
        this.tableBody = [
          ...this.json.body.slice(
            this.totallyShowed.currentIndex - 1,
            this.totallyShowed.currentIndex - 1 + this.totallyShowed.show
          ),
        ];
        return;
      }
      this.tableBody.sort((a, b) => {
        if ((a && a.subTable) || (b && b.subTable)) {
          this.tableBody.splice(this.displayedSubTable, 1);
          this.displayedSubTable = false;
          return -1;
        }
        if (a[col].val) {
          if (a[col].val.field1) a = a[col].val.field1;
          else a = a[col].val;
        } else if (a[col]) a = a[col];
        if (b[col].val) {
          if (b[col].val.field1) b = b[col].val.field1;
          else b = b[col].val;
        } else if (b[col]) b = b[col];

        if (Array.isArray(a)) a = 1.5;
        if (Array.isArray(b)) b = 1.5;
        if (a.icon)
          a = this.iconsMap[a.icon] ? this.iconsMap[a.icon].title : 1.5;
        if (b.icon)
          b = this.iconsMap[b.icon] ? this.iconsMap[b.icon].title : 1.5;
        if (!parseFloat(a) && this.allowedHeaders[col].$sorted === 2)
          return a?.localeCompare(b);
        else if (!parseFloat(a)) return Math.sign(b?.localeCompare(a));
        if (this.allowedHeaders[col].$sorted === 2)
          return parseFloat(a) - parseFloat(b);
        return parseFloat(b) - parseFloat(a);
      });
    },
    jsonTableInit() {
      this.json.body.forEach((e, i) =>
        Array.isArray(e) ? (e._ID = i) : false
      );
    },
    updateSawHistory() {
      this.tableBody.forEach((e, i) => {
        i += this.totallyShowed.currentIndex - 1;
        if (!this.totallyShowed.saw.includes(i)) this.totallyShowed.saw.push(i);
      });
    },
    nextShow(reloadCurrentPage) {
      if (
        !reloadCurrentPage &&
        this.json.body.length - this.totallyShowed.currentIndex + 1 >=
          this.totallyShowed.show
      ) {
        this.totallyShowed.currentIndex += this.totallyShowed.show;
      }

      this.$emit(
        "nextPage",
        this.totallyShowed.currentIndex - 1,
        this.totallyShowed.currentIndex - 1 + this.totallyShowed.show
      );
      this.tableBody = [
        ...this.json.body.slice(
          this.totallyShowed.currentIndex - 1,
          this.totallyShowed.currentIndex - 1 + this.totallyShowed.show
        ),
      ];
      this.clearTableOfSubdata();
      this.displayedSubData = false;
      this.displayedSubTable = false;
      this.updateSawHistory();
      this.allowedHeaders.forEach((el) => (el.$sorted = false));
      this.displayedSubTable = false;
    },
    backShow() {
      if (this.totallyShowed.currentIndex - this.totallyShowed.show >= 1) {
        this.totallyShowed.currentIndex -= this.totallyShowed.show;

        this.$emit(
          "previousPage",
          this.totallyShowed.currentIndex - 1,
          this.totallyShowed.currentIndex - 1 + this.totallyShowed.show
        );
      }

      this.tableBody = [
        ...this.json.body.slice(
          this.totallyShowed.currentIndex - 1,
          this.totallyShowed.currentIndex - 1 + this.totallyShowed.show
        ),
      ];
      this.clearTableOfSubdata();
      this.displayedSubData = false;
      this.displayedSubTable = false;
      this.updateSawHistory();
      this.allowedHeaders.forEach((el) => (el.$sorted = false));
    },
    changeRows(r) {
      if (!r && !parseInt(this.optionRowsKey) > 0) return;
      rowsHasBeenModified = true;
      this.displayedSubTable = false;
      this.totallyShowed.show = parseInt(r) || parseInt(this.optionRowsKey);
      this.totallyShowed.currentIndex = 1;
      this.tableBody = [
        ...this.json.body.slice(
          this.totallyShowed.currentIndex - 1,
          this.totallyShowed.currentIndex - 1 + this.totallyShowed.show
        ),
      ];
      this.clearTableOfSubdata();
      this.displayedSubData = false;
      this.displayedSubTable = false;
      this.updateSawHistory();
      this.allowedHeaders.forEach((el) => (el.$sorted = false));
    },
    clearTableOfSubdata() {
      this.showSubTableSlotIndex = -1;

      this.tableBody = this.tableBody
        .filter((e) => !e.subData)
        .map((e) => {
          if (e.mention) {
            delete e.mention;
          }
          if (e.bottomBorder) {
            delete e.bottomBorder;
          }
          return e;
        });
    },
    subTable(index, type, id, parentId, row) {
      if (
        !Number.isInteger(index) ||
        !Number.isInteger(type) ||
        !Number.isInteger(id)
      )
        return;

      const { specialActions } = this.json.options;
      if (this.isObject(specialActions)) {
        let action = specialActions[type];
        let extendedMode = "1";

        if (Array.isArray(action)) {
          extendedMode = action[1] || extendedMode;
          action = action[0];
        }

        if (action == "showSubTableSlot") {
          const subInd = this.showSubTableSlotIndex;

          this.showSubTableSlotIndex = index === subInd ? -1 : index;
          return;
        }
        if (action == "showModalSlot") {
          if (!row) {
            this.$toastr.e("Table error #741273");
            return;
          }
          if (this.$scopedSlots["modal-slot"]) {
            this.modalSLot.data = row._Data;
            this.modalSLot.actionBtn = type;
            this.modalSLot.show = true;
            this.modalSLot.extendedMode = extendedMode;
          }
          return;
        }
      }

      const balanceIndex = this.tableBody
        .slice(0, index)
        .filter((e) => e.subData).length;
      let ind = index + 1 - balanceIndex;
      const maxColspan = Math.max(
        this.tableBody.length,
        this.allowedHeaders.length
      );
      if (type === 1 && !this.disableSubData) {
        const x = this.subTableData[id];
        if (x?.head) {
          if (!this.displayedSubTable) {
            x.subTable = maxColspan;
            this.tableBody.splice(ind, 0, x);
            this.displayedSubTable = ind;
          } else {
            this.tableBody.splice(this.displayedSubTable, 1);

            if (this.displayedSubTable !== ind) {
              x.subTable = maxColspan;
              this.tableBody.splice(ind, 0, x);
              this.displayedSubTable = ind;
            } else this.displayedSubTable = false;
          }
        } else {
          (() => {
            if (this.displayedSubData) {
              this.clearTableOfSubdata();

              if (this.displayedSubData === ind) {
                this.displayedSubData = false;
                return;
              }
            }
            this.displayedSubData = ind;
            const insertData = (subD, clearOld) => {
              if (clearOld) {
                this.clearTableOfSubdata();
              }

              const data = this.tableBody;
              let subTableData = [[]];
              const sliceAt = ind;

              if (Array.isArray(subD) && subD.flat(1).length) {
                subTableData = subD;
                this.subTableData[id] = subD;
              }

              const prepData = subTableData.map((e) =>
                Object.assign(e, { subData: true, isEmpty: !!subD["isEmpty"] })
              );
              prepData.forEach((e) => {
                if (e[0]?.val && String(e[0].val).indexOf(".") === -1) {
                  e[0].val = `${id + 1}.${e[0]?.val}`;
                } else if (e[1]?.val && String(e[1].val).indexOf(".") === -1) {
                  e[1].val = `${id + 1}.${e[1]?.val}`;
                }
              });

              const prepared = [
                ...data.slice(0, sliceAt),
                ...prepData,
                ...data.slice(sliceAt),
              ];

              const sliceEnd = subTableData.length + sliceAt;
              const z = sliceAt - 1;
              for (let i = z; i < sliceEnd; i++) {
                prepared.splice(
                  i,
                  1,
                  Object.assign(
                    prepared[i] || {},
                    { mention: true },
                    i === z ? { bottomBorder: true } : { parentId: id }
                  )
                );
              }

              this.tableBody = prepared;
            };
            if (Array.isArray(x) && x.flat(1).length) {
              insertData(x);
            } else {
              insertData([[]]);
              this.$emit("subData", id, (x) => insertData(x, true));
            }
          })();
        }
      } else {
        this.$emit(`Btn${type || ""}`, id, parentId);
        this.$emit("BtnEvent", type, id, parentId);
      }
    },
    getTableSizeWithoutScrollbar() {
      const table = this.$refs.Table_Parent;
      return {
        width: table?.clientWidth,
        height: table?.clientHeight,
      };
    },
    getTableSize() {
      const table = this.$refs.Table?.getBoundingClientRect();
      return {
        width: table?.width,
        height: table?.height,
      };
    },
    syncTableWidth() {
      this.tableWidth = this.getTableSize()?.width - 20;
      this.tableClientWidth = this.getTableSizeWithoutScrollbar()?.width - 20;
    },
    syncTableHeaderHeight() {
      const thead = this.$refs["thead"];
      const theadRow = this.$refs["theadRow"];

      const setHeight = (el, key) => {
        if (!el?.getBoundingClientRect) return;

        const rect = el.getBoundingClientRect();
        if (rect) {
          this[key] = rect.height ?? 0;
        }
      };

      setHeight(thead, "headerHeight");
      setHeight(theadRow, "headerRowHeight");
    },
    updateJson() {
      if (!this.tableBody.length || this.json.options.refresh) {
        this.totallyShowed.saw.length = 0;
        this.totallyShowed.currentIndex = 1;
        this.tableBody = [...this.json.body.slice(0, this.totallyShowed.show)];
        this.totallyShowed.saw.splice(0);
        this.updateSawHistory();

        delete this.json.options.refresh;
      }
      if (!rowsHasBeenModified) {
        const x = parseInt(this.Data.options.show);
        if (Number.isInteger(x)) this.optionRowsKey = x;
      }
    },
    syncDisabledCols() {
      if (Array.isArray(this.disabledCols)) {
        for (let row of this.json.body) {
          if (!Array.isArray(row)) return;

          for (let index of this.disabledCols) {
            row.splice(index, 1);
          }
        }
      }
    },
  },
  created() {
    if (typeof this.json.options !== "object") {
      this.json.options = {};
    }
    this.tableId = `ID${this.makeid(10)}`;

    this.syncDisabledCols();

    if (this.json.options && this.json.options.totalItems) {
      this.totallyShowed.totalItems = this.json.options.totalItems;
    } else {
      this.totallyShowed.totalItems = this.json.body.length;
    }

    this.updateSawHistory();
    if (!this.optionRows.find((el) => el === parseInt(this.optionRowsKey))) {
      this.optionRows.push(parseInt(this.optionRowsKey));
      this.optionRows.sort((a, b) => a - b);
    }

    this.tableHead = [
      ...this.json.head.filter((e) => e.show !== false),
    ].map((e) => ({ ...e, $sorted: false }));
    this.tableBody = [...this.json.body.slice(0, this.totallyShowed.show)];
    this.subTableData = { ...this.json.subTableData };

    this.updateJson();
    this.changeRows();

    if (this.isEmpty) return;

    if (this.viewRows) {
      this.$watch("viewRows", (val) => {
        this.checkedRows = val;
      });
    }

    this.$watch("modalSLot.show", (x) => {
      if (!x) {
        this.modalSLot.data = null;
      }
    });
  },
  updated() {
    this.syncTableWidth();
    this.syncTableHeaderHeight();
  },
  mounted() {
    this.$emit("mounted");

    this.syncTableWidth();
    this.syncTableHeaderHeight();

    if (this.json.options?.style && this.$refs.Table)
      this.$refs.Table.classList.add(String(this.json.options.style));
    this.jsonTableInit();

    erdUltraFast.listenTo(this.$refs["Table_Parent"], this.syncTableWidth);
    erdUltraFast.listenTo(
      this.$refs["Table_Parent"],
      this.syncTableHeaderHeight
    );
  },
  beforeDestroy() {
    erdUltraFast.removeListener(
      this.$refs["Table_Parent"],
      this.syncTableWidth
    );
    erdUltraFast.removeListener(
      this.$refs["Table_Parent"],
      this.syncTableHeaderHeight
    );
  },
};
</script>

<style>
.MultiLevelCellButtons_Panel .cell_butt:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.MultiLevelCellButtons_Panel .cell_butt:active {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
