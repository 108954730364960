/*
  This mixin is necessary for table components which have filtering options.
*/

const queryString = require("query-string")
const GET_FIRST_PAGES = 100

let modules = null
const moduleNames = {
  CPV_LIST: ["CPV_CATEGORY_PAGINATED", "cpvCode", { spacerBeforeSearch: true }],
  MU_LIST: ["MEASUREMENT_UNIT_PAGINATED", "shortName"],
  CONTRACT_TYPE: ["CONTRACT", "contractType"],
  FINANCING: ["FINANCING", "sourceName"],
}
const combinedQueryesMap = {
  allUser: "first_name,last_name,id",
  userName: "first_name,last_name",
}

export default {
  data() {
    return {
      lastSentCount: 0,
      fixedCellsRowsCache: [],
    }
  },
  methods: {
    clearAllFilter() {
      this.$emit("fullReload")
    },
    async loadModules() {
      modules = await import("@/api.js")
    },
    async getDropdownData(scope) {
      try {
        if (!moduleNames[scope.metaType]) {
          this.$toastr.e(
            "Coloana selectată nu este pregătită pentru opțiunile de căutare."
          )
          return
        }
        const api = modules[moduleNames[scope.metaType][0]]
        const queryKey = moduleNames[scope.metaType][1]
        if (!(api && queryKey)) {
          this.$toastr.e("Type error: API not found")
          return
        }
        return await new Promise((resolve) => {
          ;(typeof api.get == "function" ? api.get : api)(
            1,
            GET_FIRST_PAGES,
            moduleNames[scope.metaType][2]
              ? false
              : [queryKey, scope.inputValue]
          )
            .then((res) => {
              if (!(typeof res === "object" && res !== null)) {
                this.$toastr.e(
                  `<b>TypeError: Server error</b><br>Controlați consola pentru detalii.`
                )
                console.error(
                  `TypeError: Server error - datele nu au fost primite corect în urma îndeplinirii fetch.`
                )
                return
              }
              if (
                !(
                  res.meta?.HttpStatusCode &&
                  this.checkHttpStatusCode(res.meta.HttpStatusCode)
                )
              ) {
                this.$toastr.e(
                  `HttpStatusCode - ${res.meta?.HttpStatusCode ||
                    "(not found)"}`
                )
                return
              }
              if (!(res.data?.result && Array.isArray(res.data.result))) {
                this.$toastr.e("Search error: Data not found")
                return
              }
              resolve(Array.from(res.data?.result))
            })
            .catch((err) => {
              this.$toastr.e(
                `<b>Controlați conexiunea</b> <br> ${err}`
              )
            })
        })
      } catch (err) {
        console.error(err)
        if (this.$toastr) this.$toastr.e(err)
      }
    },
    updateTableData(prepared, query) {
      const x = Number.isInteger(prepared[1]) ? prepared[1] : false

      this.$emit("filterQuery", query)
      this.json.body = prepared[0].length ? prepared[0] : [false]
      this.tableBody = [...this.json.body.slice(0, this.totallyShowed.show)]
      this.showOnlyCurrentItemsTotalShow = !!query
      this.totalItemsCount = x
      this.totallyShowed.saw.length = 0
      this.totallyShowed.currentIndex = 1
      this.totallyShowed.totalItems = x || 0
      this.updateSawHistory()
      this.displayedSubData = false
      this.subTableData = { ...this.json.subTableData }
    },
    prepareGeneralQuery(data) {
      const searchModule = this.json?.options?.searchModule

      if (!(data && Array.isArray(data)) || !searchModule) {
        return
      }

      const filterFunctions = []
      let _query = {}
      data.forEach((e) => {
        let a, b
        if (this.isString(e.queryKey)) {
          const x = e.queryKey.split(":")
          a = x[0]
          b = x[1]
        }

        if (a && (e.inputValue || e.inputValue === 0)) {
          if (Array.isArray(e.inputValue)) {
            const pos = []
            const eqPos = []
            const eqnPos = []
            const neg = []
            e.inputValue.forEach((e) => {
              ;(Array.isArray(e) ? (e[1] ? ({
                'true': eqPos,
                'eqn': eqnPos
              }[e[1]] || []) : neg) : pos).push(e)
            })
            if (pos.length) {
              _query[String(a)] = combinedQueryesMap[b]
                ? `${combinedQueryesMap[b]}||${pos}`
                : pos;
              
              if(typeof e.bottomHead.filterFn == 'function') {
                filterFunctions.push(e.bottomHead.filterFn)
              }
            }

            neg.forEach((e) => {
              if (!e.length) return
              _query["neq"] = `${a},${e[0]}`
            })

            eqPos.forEach((e) => {
              if (!e.length) return
              _query["eq"] = `${a},${e[0]}`
            })
            
            eqnPos.forEach((e) => {
              if (!e.length) return
              _query["eqn"] = `${a},${e[0]}`
            })
          } else {
            _query[String(a)] = combinedQueryesMap[b]
              ? `${combinedQueryesMap[b]}||${e.inputValue}`
              : e.inputValue
          }
        } else {
          delete _query[String(a)]
        }
      })
      const query =
        queryString.stringify(_query, { arrayFormat: "comma" }) +
        (this.fixedFilter ? `&${this.fixedFilter}` : "")

      try {
        this.lastSentCount++
        this.tableBody = []
        this.doNotDisableFiltreControl = false
        
        if (!(!query && this.json.options.onlyFilterCalls)) {
          ;((x) => {
            ;(() => {
              let x =
              typeof searchModule == 'function' ? searchModule : modules[
                  this.useSearchModule || 
                  (
                    this.avizoMode
                      ? "NEEDS_AVIZO"
                      : searchModule
                  )
                ]
              return typeof x?.get == "function" ? x.get : x
            })()(
              1,
              this.optionRowsKey * 2,
              false,
              searchModule == "CPV_CATEGORY_PAGINATED" ||  searchModule == "BUGETARE"
                ? false
                : query,
              query
            )
              .then((data) => {
                if (x !== this.lastSentCount) {
                  return
                }
                if (
                  Array.isArray(data?.data?.result)
                ) {
                  data.data.result = data.data.result.filter(e => {
                    for(let fn of filterFunctions) {
                      if(!fn(e)) {
                        return false
                      }
                    }
                    
                    return true
                  })

                  // if(Number.isInteger(data.data.recordsQuantity)) {
                  //   data.data.recordsQuantity = null
                  // }
                }

                this.$emit(
                  "readFilteredData",
                  data,
                  (x) => {
                    this.updateTableData(x, query);
                    this.syncDisabledCols();
                  },
                  query
                )
              })
              .catch((err) => {
                this.updateTableData([false], query)
                this.$toastr.e(err)
              })
          })(this.lastSentCount)
        }
      } catch (err) {
        this.$toastr.e(err)
        console.error(err)
      }
    },
  },
  created() {
    this.loadModules()
  },
}
