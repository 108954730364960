export default {
  components: {
    'date-picker': () => {
      const x = import('vue2-datepicker')
      import('vue2-datepicker/index.css')
      import('vue2-datepicker/locale/ro')

      return x
    }
  }
}