<template>
  <th
    class="Table_head_cell CELL"
    :class="{
      clickable: cell.sort,
      shadowLeft: cell.shadow == 'left',
      shadowRight: cell.shadow == 'right',
    }"
    style="padding: 1rem 0.5rem !important;"
    :style="headStyles"
    @click="$emit('sortTable', index)"
  >
    <div
      v-if="!cell.spacer"
      class="cell_inner"
      :class="{
        sorted: cell.$sorted,
        sta: cell.title.align === 'left',
        stb: cell.title.align === 'right',
      }"
    >
      <span
        v-if="cell.title && !cell.checkbox"
        class="Table_head_cell_content"
        v-html="
          cell.title.val && cell.title.val
            ? cell.title.val
            : cell.title !== false
            ? cell.title
            : ''
        "
      >
      </span>
      <input
        v-else-if="cell.checkbox"
        class="Table_head_cell_content"
        type="checkbox"
        @change="$emit('select-all', $event)"
      />
      <div v-if="cell.sort" class="sortVector"></div>
    </div>
    <div v-else style="display: block; width: 3rem;"></div>
  </th>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
      default: -1,
    },
    cell: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    headStyles() {
      const x = this.cell;
      return (
        (x.minWidth ? `min-width: ${x.minWidth} !important;` : "") +
        (x.title
          ? (x.title.val || x.title).length > 30
            ? "min-width: 20rem;"
            : ""
          : "")
      );
    },
  },
};
</script>
