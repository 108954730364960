<template>
  <td
    class="topPanelCell"
    :class="{
      shadowLeft: cell.shadow == 'left',
      shadowRight: cell.shadow == 'right',
    }"
  >
    <i
      v-if="cell.bottomHead.icon"
      v-tooltip="{
        content: cell.bottomHead.tooltip || '',
        placement: 'top',
      }"
      :class="cell.bottomHead.icon"
      :style="cell.bottomHead.css"
    ></i>
    <search
      v-else
      v-tooltip="cell.bottomHead.tooltip"
      :index="index"
      :item="cell"
      :reset="resetSearch"
      :selectedItemsIndex="selectedItemsIndex"
      :avizoMode="avizoMode"
      :approveMode="approveMode"
      @changed="(a, b) => $emit('searchManager', a, b)"
      @inputted="(a, b) => $emit('loadSearchData', a, b)"
      @openSelectedItems="(a) => $emit('setSelectedItemsWindow', a)"
      @clearAllFilter="$emit('clearAllFilter')"
    />
  </td>
</template>

<script>
import Search from './SearchChell'

export default {
  props: {
    index: {
      type: Number,
      required: true,
      default: -1,
    },
    cell: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    resetSearch: {
      type: Number,
      default: 0,
    },
    selectedItemsIndex: {
      type: Number,
      default: -1,
      required: true,
    },
    avizoMode: {
      type: Boolean,
      default: true,
    },
    approveMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Search
  },
  computed: {
    
  },
}
</script>
