<template>
  <td
    class="CELL"
    v-tooltip="getCellValue(cell)"
    :class="{
      subRow: row.mention,
      subRowB: row.bottomBorder || showSubTableSlotIndex === rowIndex,
      borderL:
        cell.border === 'left' || Array.isArray(cell) || cell.border === true,
      borderR: cell.border === 'right' || cell.border === true,
    }"
    :style="cell.spacer && !cell.minimize ? 'min-width: 20rem;' : ''"
    height="50"
  >
    <template v-if="!cell.spacer">
      <div class="cell_inner">
        <span
          v-if="
            (typeof cell !== 'object' ||
              cell.val ||
              cell.icon ||
              cell.checkbox) &&
              !Array.isArray(cell)
          "
          class="Table_body_cell_content"
          :class="{
            sta: cell.align === 'left',
            stb: cell.align === 'right',
          }"
        >
          <input
            v-if="cell.checkbox"
            style="margin: 0 !important"
            :style="cellParser(cell) ? 'margin-right: 1rem !important;' : ''"
            type="checkbox"
            :value="{ index: row._ID, id: row.id }"
            v-model="checkedRowsInput"
            @change="syncCheckedRows"
          />
          <span
            v-if="cell.val && cell.val.field1"
            :style="cell.val.color ? { backgroundColor: cell.val.color } : {}"
            class="field1"
          >
            {{ cell.val.field1 }}
          </span>
          <span
            v-else-if="cell.icon"
            class="icoType1"
            :style="
              cell.css ||
                (iconsMap[Array.isArray(cell.icon) ? cell.icon[0] : cell.icon]
                  ? iconsMap[
                      Array.isArray(cell.icon) ? cell.icon[0] : cell.icon
                    ].css
                  : '') +
                  (cell.iconColor ? `color:${cell.iconColor} !important;` : '')
            "
          >
            <img
              v-if="Array.isArray(cell.icon)"
              :src="getImgUrl(cell.icon[0])"
              alt=""
            />
            <i v-else :class="cell.icon"></i>
          </span>
          <span
            v-else
            style="text-align: center"
            v-html="cellParser(cell)"
          ></span>
        </span>
        <span
          v-else-if="cell && cell.field1"
          :style="cell.color ? { backgroundColor: cell.color } : {}"
          class="field1"
        >
          {{ cell.field1 }}
        </span>
        <div v-else class="cell_butts">
          <template v-if="!Array.isArray(cell[0])">
            <div
              v-for="btn in cell"
              :key="btn[0] || btn"
              class="cell_butt"
              :class="'ImG' + btn"
              :style="btn == false ? 'pointer-events: none;' : ''"
              @click="
                $emit('subTable', rowIndex, btn, row._ID, row.parentId, row)
              "
              v-tooltip="tableBtnTitle(btn)"
              :data-rowIndex="index + appendToColIndex"
            >
              <div class="ImGp">
                <i
                  v-if="Array.isArray(img[btn])"
                  :class="img[btn][0]"
                  style="font-size: 2.2rem; color: #39f"
                ></i>
                <img v-else class="imgIco" :src="img[btn]" @load="imgLoaded" />
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-if="cell[0].length"
              v-click-outside="closeMultilevelButtons"
              class="MultiLevelCellButtons"
            >
              <transition name="Butt_Panel">
                <div
                  v-show="panelButtonsIndex === rowIndex"
                  @click.stop=""
                  class="MultiLevelCellButtons_Panel"
                >
                  <div
                    v-for="btn in cell[0]"
                    :key="btn[0] || btn"
                    v-tooltip="readBtn(btn, true)"
                    @click="
                      () => {
                        setPBI();
                        $emit(
                          'subTable',
                          rowIndex,
                          readBtn(btn),
                          row._ID,
                          row.parentId,
                          row
                        );
                      }
                    "
                    class="ImGp cell_butt"
                    style="cursor: pointer"
                  >
                    <i
                      v-if="Array.isArray(img[readBtn(btn)])"
                      :class="img[readBtn(btn)][0]"
                      style="font-size: 2.2rem; color: #39f"
                    ></i>
                    <i
                      v-else-if="btn[2]"
                      :class="btn[2][0]"
                      :style="btn[2][1]"
                    ></i>
                    <img
                      v-else
                      class="imgIco"
                      :src="img[readBtn(btn)]"
                      @load="imgLoaded"
                      style="pointer-events: none"
                    />
                  </div>
                </div>
              </transition>
              <div
                @click.stop="
                  setPBI(
                    panelButtonsIndex == -1 || panelButtonsIndex != rowIndex
                      ? rowIndex
                      : -1
                  )
                "
                class="ImGp cell_butt"
              >
                <img class="imgIco" :src="img[10]" @load="imgLoaded" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </td>
</template>

<script>
export default {
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    cell: {
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    appendToColIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    tableClientWidth: {
      type: Number,
      default: -1,
    },
    panelButtonsIndex: {
      type: Number,
      default: -1,
    },
    showSubTableSlotIndex: {
      type: Number,
      default: -1,
    },
    row: {
      type: Array,
      required: true,
    },
    iconsMap: {
      type: Object,
      default: () => ({}),
    },
    allowedHeaders: {
      type: Array,
      default: () => [],
    },
    img: {
      type: Array,
      default: () => [],
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      buttsMap: [
        "width: 3.5rem !important;",
        "width: 5rem !important;",
        "padding: 0 0.5rem !important;",
      ],
      checkedRowsInput: [...this.checkedRows],
    };
  },
  watch: {
    checkedRows(x) {
      this.checkedRowsInput = x;
    },
  },
  methods: {
    setPBI(x) {
      if (!Number.isInteger(x)) x = -1;

      this.$emit("PBI", x);
    },
    getImgUrl(pet) {
      const images = require.context("@/assets/media/for_table", false);
      return images(`./${pet}`);
    },
    getCellValue(cell) {
      let result = "";
      if (!cell) {
        return "";
      }
      const isTooltip = !!cell.tooltip;
      cell = cell.tooltip || cell;

      if (cell.val) {
        result = String(cell.val.field1 || cell.val);
      } else if (cell.icon) {
        const findIcon = this.iconsMap[cell.icon[0] || cell.icon];
        result = findIcon?.title || "";
      } else if (!(typeof cell === "object" && cell !== null)) {
        result = String(cell);
      }
      if (isTooltip) {
        return result;
      }
      if (
        !result &&
        (cell.spacer !== undefined ||
          cell.checkbox !== undefined ||
          Array.isArray(cell))
      ) {
        return "";
      }
      return cell.icon || result.length > 6 ? result : "";
    },
    cellParser(value) {
      if (value.checkbox) {
        return "";
      }
      if (value !== false) {
        if (value.val) value = value.val;
        return value;
      } else return "";
    },
    readBtn(btn, returnTitle) {
      if (Array.isArray(btn)) {
        return returnTitle ? btn[1] : btn[0];
      }
      if (returnTitle) {
        return this.tableBtnTitle(btn);
      }
      return btn;
    },
    tableBtnTitle(btn) {
      const titleMap = [
        "Cheltuieli",
        "Nevoi simple",
        "Modifică",
        "Ștergere",
        "Generează RP",
        "Fișiere",
        "Lista de necesități",
        "Vezi detalii",
        "Aprobă",
        "Respinge",
        "Explorează",
        "Informații suplimentare",
        "Solicită revizuire",
        "Istoric",
        "Previzualizare PDF",
        "Departamente",
        "Detalii",
      ];

      return titleMap[btn] || "Button";
    },
    closeMultilevelButtons() {
      this.setPBI();
    },
    syncCheckedRows() {
      this.checkedRows.splice(
        0,
        this.checkedRows.length,
        ...this.checkedRowsInput
      );
      this.checkedRowsInput = [...this.checkedRows];
      this.$emit("checkboxManager");
    },
  },
  created() {},
};
</script>

<style></style>
